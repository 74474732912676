import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';

import {
  TextField,
  Button,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

import { classes, Form } from '../style';

import featureFlagManager, { FLAG } from 'modules/featureFlagManager';
import { PHOTON_GRAPH_URL } from 'environmentConfig';

import useEmailValidation from 'hooks/useEmailValidation';
import {
  sendUserRegistrationAlert,
  usePasswordResetService,
} from 'services/passwordResetService.js';
import { DropdownSelector } from 'modules/dropdownSelector/index.jsx';
import { RegisterSuccess } from './components/registerSuccess.js';
import { RegisterWithGoogle } from './components/registerGoogle.jsx';
import ReactGA from 'react-ga4';
import { ROUTE } from 'routes';
import { PAGES } from 'pages';
import { FixMeLater } from 'any.js';

const INSERT_USER = gql`
  mutation SetUser(
    $id: String
    $email: String!
    $firstname: String!
    $lastname: String!
    $description: String!
    $institution: Int!
    $createdBy: String
    $photourl: String
    $roles: [String]
  ) {
    insertUser(
      input: {
        id: $id
        email: $email
        firstname: $firstname
        lastname: $lastname
        password: $password
        description: $description
        institution: $institution
        createdby: $createdBy
        photourl: $photourl
        roles: $roles
      }
    ) @rest(type: "Success", path: "/users/", method: "POST", endpoint: "v1") {
      success
      message
    }
  }
`;

const GET_INSTITUTIONS = gql`
  query getInstitutions {
    institutions {
      nodes {
        id
        name
        psiFlag
      }
    }
  }
`;

interface RegisterFormPropTypes {
  setForm?: React.Dispatch<React.SetStateAction<string>>;
  setMsg: React.Dispatch<React.SetStateAction<string>>;
  showErrorMsg: React.Dispatch<React.SetStateAction<boolean>>;
  admin?: any;
  onSuccess?: ({
    email,
    description,
    firstname,
    lastname,
    institution,
  }: {
    email: any;
    description: any;
    firstname: any;
    lastname: any;
    institution: any;
  }) => Promise<void>;
  userInfoFromLogin: any;
  setRedirect?: React.Dispatch<React.SetStateAction<any>>;
}

export const RegisterForm = ({
  setForm,
  setMsg,
  showErrorMsg,
  admin,
  onSuccess,
  userInfoFromLogin = null,
  setRedirect,
}: RegisterFormPropTypes) => {
  ReactGA.send({
    hitType: 'pageview',
    page: ROUTE.REGISTER,
    title: PAGES.REGISTER,
  });
  const navigate = useNavigate();
  const { sendResetPasswordEmail } = usePasswordResetService();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [description, setDescription] = useState('');
  const [rolesSelected, setRolesSelected] = useState<string[]>(['regular']);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const { email, setEmail, emailError, validateEmail } = useEmailValidation('');
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [roleName, setRoleName] = useState(['regular']);
  const [roleValid, setRoleValid] = useState(true);

  const handleChange = (
    event: SelectChangeEvent<typeof rolesSelected>
  ): void => {
    const {
      target: { value },
    } = event;
    value.length > 0 ? setRoleValid(true) : setRoleValid(false);
    setRolesSelected(typeof value === 'string' ? value.split(',') : value);
    setRoleName(typeof value === 'string' ? value.split(',') : value);
  };

  const [insertUser] = useMutation(INSERT_USER);

  const { loading, data } = useQuery(GET_INSTITUTIONS, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  useEffect(() => {
    validateEmail();
  }, [email]);

  // Validation errors
  const firstnameError =
    hasAttemptedSubmit && firstname === '' ? 'Firstname is required' : '';
  const lastnameError =
    hasAttemptedSubmit && lastname === '' ? 'Lastname is required' : '';
  const institutionError =
    hasAttemptedSubmit && selectedInstitution === ''
      ? 'Institution is Required'
      : '';

  const handleRegister = async (): Promise<void> => {
    setHasAttemptedSubmit(true);
    if (!validateEmail()) {
      setMsg(emailError);
      showErrorMsg(true);
      return;
    } else if (firstnameError || lastnameError || institutionError) {
      setMsg('Please fill in all fields correctly');
      showErrorMsg(true);
      return;
    }

    try {
      const register = await insertUser({
        variables: {
          email: email.toLowerCase(),
          firstname,
          lastname,
          description,
          institution: selectedInstitution,
          createdBy: admin ? admin.email : null,
          roles: rolesSelected,
        },
      });
      if (!register.data.insertUser.success) {
        if (register.data.insertUser.message === 'email') {
          setMsg('Email is already in use');
          showErrorMsg(true);
        }
        return;
      }
      if (admin && onSuccess) {
        onSuccess({
          email,
          firstname,
          lastname,
          description,
          institution: selectedInstitution,
        });
      } else {
        setRegisterSuccess(true);
        showErrorMsg(false);
        setMsg('');
        const institutionName = institutionOptions.find(
          institution => institution.value === selectedInstitution
        );
        sendUserRegistrationAlert({
          email,
          firstname,
          lastname,
          institution: institutionName.label,
        });
        await sendResetPasswordEmail({ firstname, email }, true);
      }
    } catch (err: any) {
      console.error(err.message);
      setMsg(admin ? 'Error in adding user' : 'Error in registration');
      showErrorMsg(true);
    }
  };

  const handleGoogle = async () => {
    setMsg('');
    showErrorMsg(false);
    setRedirect(true);
    handleForm('login');
  };

  const handleGoogleRegister = async () => {
    try {
      await insertUser({
        variables: {
          id: userInfoFromLogin.uid,
          email: userInfoFromLogin.email,
          firstname: userInfoFromLogin.displayName.split(' ')[0],
          lastname: userInfoFromLogin.displayName.split(' ')[1],
          description,
          institution: selectedInstitution,
          createdBy: null,
          photourl: userInfoFromLogin.photoURL,
          roles: rolesSelected,
        },
      });
      setRegisterSuccess(true);
      showErrorMsg(false);
      setMsg('');
      const institutionName = institutionOptions.find(
        institution => institution.value === selectedInstitution
      );
      sendUserRegistrationAlert({
        email: userInfoFromLogin.email,
        firstname: userInfoFromLogin.displayName.split(' ')[0],
        lastname: userInfoFromLogin.displayName.split(' ')[1],
        institution: institutionName.label,
      });
    } catch (err: any) {
      console.error(err.message);
      setMsg('Error in registration');
      showErrorMsg(true);
    }
  };

  const isSmallScreen = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  const handleForm = (type: string): void => {
    if (
      isSmallScreen &&
      featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)
    ) {
      navigate('/' + type);
    }
    if (setForm) {
      setForm(type);
    }
    setMsg('');
    showErrorMsg(false);
  };

  let institutionOptions: FixMeLater[] = [];

  if (!loading) {
    const institutions = [...data.institutions.nodes];
    institutionOptions = institutions
      .sort((a, b) => {
        if (a.psiFlag !== b.psiFlag) {
          return a.psiFlag ? 1 : -1;
        } else {
          return a.name.localeCompare(b.name);
        }
      })
      .map(institute => ({
        label: institute.name,
        value: institute.id,
        postSecondary: institute.psiFlag ? 'Post-secondary institutions' : '',
        disabled: false,
      }));
  }

  return (
    <Form className={classes.container}>
      {registerSuccess ? (
        <RegisterSuccess handleForm={handleForm} setRedirect={setRedirect} />
      ) : userInfoFromLogin === null ? (
        <>
          <form>
            <div style={{ display: 'flex', marginBottom: '20px' }}>
              <TextField
                id="outlined-basic"
                label="First Name"
                value={firstname}
                onChange={e => setFirstname(e.target.value)}
                error={!!firstnameError}
                style={{ marginRight: '20px', flex: '1' }}
                variant="outlined"
                autoComplete="given-name"
              />
              <TextField
                id="outlined-basic"
                label="Last Name"
                value={lastname}
                onChange={e => setLastname(e.target.value)}
                error={!!lastnameError}
                style={{ flex: '1' }}
                variant="outlined"
                autoComplete="family-name"
              />
            </div>
            <TextField
              id="outlined-basic"
              label="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              error={hasAttemptedSubmit && !!emailError}
              style={{ marginBottom: '20px', width: '100%' }}
              variant="outlined"
              autoComplete="email"
              inputProps={{ inputMode: 'email' }}
            />
            <TextField
              id="outlined-basic"
              label="Intended Use (Optional)"
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="Ex: Your role and purpose for using SkillUp"
              style={{ marginBottom: '20px', width: '100%' }}
              variant="outlined"
            />

            <DropdownSelector
              options={institutionOptions}
              id="institution-select"
              value={selectedInstitution}
              label={'Institution'}
              onChange={(value: string) => setSelectedInstitution(value)}
              group={'postSecondary'}
              dropdownStyles={null}
            />
            {admin?.superuser && (
              <>
                <Typography sx={{ marginTop: '20px' }}>User Type</Typography>
                <div>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={roleName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Role" />}
                      renderValue={selected => selected.join(', ')}
                    >
                      <MenuItem key="regular" value="regular">
                        <Checkbox checked={roleName.indexOf('regular') > -1} />
                        <ListItemText primary="Regular" />
                      </MenuItem>
                      <MenuItem key="admin" value="admin">
                        <Checkbox checked={roleName.indexOf('admin') > -1} />
                        <ListItemText primary="Admin" />
                      </MenuItem>
                      <MenuItem key="psi_admin" value="psi_admin">
                        <Checkbox
                          checked={roleName.indexOf('psi_admin') > -1}
                        />
                        <ListItemText primary="PSI Admin" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
          </form>
          <Button
            variant="contained"
            style={{ margin: '20px 0px' }}
            onClick={handleRegister}
            disabled={
              !firstname ||
              !lastname ||
              !email ||
              !selectedInstitution ||
              !roleValid
            }
          >
            {admin ? 'Add User' : 'Register for Access'}
          </Button>
          {!admin && (
            <>
              <Divider>or</Divider>
              <Button
                variant="outlined"
                className={classes.googleButton}
                onClick={() => handleGoogle()}
              >
                <img
                  alt=""
                  src="/assets/google-logo.png"
                  style={{ marginRight: '5px' }}
                  height={20}
                  width={20}
                />
                Continue With Google
              </Button>
              <Button variant="text" onClick={() => handleForm('login')}>
                Already have an account? Log in
              </Button>
            </>
          )}
        </>
      ) : (
        <RegisterWithGoogle
          userInfo={userInfoFromLogin}
          description={description}
          setDescription={setDescription}
          institutionOptions={institutionOptions}
          selectedInstitution={selectedInstitution}
          setSelectedInstitution={setSelectedInstitution}
          handleGoogleRegister={handleGoogleRegister}
        />
      )}
    </Form>
  );
};
